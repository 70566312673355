import React, {FC} from 'react';
import apple_platform from "../assets/images/platform_logo/apple.svg";
import spotify_platform from "../assets/images/platform_logo/spotify.svg";
import yandex_platform from "../assets/images/platform_logo/yandex.svg";
import deezer_platform from "../assets/images/platform_logo/deezer.svg";
import google_platform from "../assets/images/platform_logo/google.svg";
import amazon_platform from "../assets/images/platform_logo/amazon.svg";
import shazam_platform from "../assets/images/platform_logo/shazam.svg";
import vk_platform from "../assets/images/platform_logo/vk.svg";
import itunes_platform from "../assets/images/platform_logo/itunes.svg";
import youtube_music_platform from "../assets/images/platform_logo/youtube_music.svg";
import youtube_red_platform from "../assets/images/platform_logo/youtube_red.svg";
import {ReactSVG} from "react-svg";
import {EMusicPlatforms} from "../utils/iApp";

const platforms: { [key in EMusicPlatforms]: { img: any, link: string } } = {
  apple: {img: apple_platform, link: '#'},
  spotify: {img: spotify_platform, link: '#'},
  yandex: {img: yandex_platform, link: '#'},
  deezer: {img: deezer_platform, link: '#'},
  amazon: {img: amazon_platform, link: '#'},
  google: {img: google_platform, link: '#'},
  shazam: {img: shazam_platform, link: '#'},
  // vk: {img: vk_platform, link: '#'},
  itunes: {img: itunes_platform, link: '#'},
  youtube_music: {img: youtube_music_platform, link: '#'},
  youtube_red: {img: youtube_red_platform, link: '#'},
}

interface Props {
  platform: EMusicPlatforms;
  link?: boolean;
  className?: string;
}

const MusicPlatformIcon: FC<Props> = ({platform, link, className}) => {
  const item = platforms[platform];
  if (!item) return null;
  if (link) return (
    <a href={item.link} className={className}>
      <div className='platform-icon'><ReactSVG src={item.img}/></div>
    </a>
  )
  return (
    <div className={className}>
      <div className='platform-icon'><ReactSVG src={item.img}/></div>
    </div>
  );
}

export default MusicPlatformIcon;