import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import TrackInfo from "./TrackInfo";


const speed = 4000;

interface Props {
  tracks: any[];
}

const TracksSlider: FC<Props> = ({tracks}) => {
  const {t} = useTranslation();
  const _interval: any = useRef(null);
  const [ready, setReady] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [activeIdx, setActiveIdx] = useState(0);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, []);

  const handleVisibilityChange = useCallback(() => {
    setAutoplay(!document.hidden);
  }, []);

  useEffect(() => {
    if (ready) {
      if (autoplay) {
        _interval.current = setInterval(setTimer, speed);
      } else {
        clearInterval(_interval.current);
      }
    }
  }, [autoplay, ready])

  useEffect(() => {
    init();
    return () => {
      clearInterval(_interval.current);
    }
  }, []);

  const init = async () => {
    const startData: any = [tracks.at(-1), tracks.at(-2), ...tracks.slice(0, 3)]
    setData(startData);
    setReady(true);
  }

  const toggleAutoplay = useCallback((autoplay: boolean) => () => {
    setAutoplay(autoplay);
  }, []);

  const handleClick = useCallback((i: number) => () => {
    if (i - activeIdx === 3) {
      setTimer()
    } else if (i - activeIdx === 1) {
      setTimer(true)
    }
  }, [activeIdx]);

  const setTimer = (dec?: boolean) => {
    setActiveIdx(idx => {
      let nextIdx = idx + (dec ? -1 : 1);
      setData((prevData: any) => {
        if(prevData.length - nextIdx >= 5 || dec)  return prevData;
        const nextData = tracks?.[(nextIdx % tracks.length + 2)] || tracks[nextIdx % tracks.length - 4];
        return [...prevData, nextData]
      })
      return nextIdx;
    })
  }

  const activeTrack = data[activeIdx + 2];

  return (
    <div className='slider-wrap' onMouseOver={toggleAutoplay(false)} onMouseLeave={toggleAutoplay(true)}>
      <div className='slider-wrap-last-releases'>
        <span className='position-absolute'>{t('LAST_RELEASES')}</span>
      </div>
      <div className='custom-sider'>
        {data.map((item: any, i: number) => {
          const idx = i - activeIdx
          if (idx < -3) return null;
          return <div key={i} className={`custom-sider-item custom-sider-item_${idx}`} onClick={handleClick(i)}>
            <img src={item.img} alt="item"/>
            {/*{item.id}*/}
          </div>
        })}
      </div>
      <TrackInfo track={activeTrack} className='slider-info'/>
    </div>
  );
}

export default TracksSlider;
