import React, {FC, useEffect, useState} from 'react';
import './header.css'
import {ReactSVG} from "react-svg";
import logo from '../../assets/images/logo.svg';
import {useTranslation} from "react-i18next";
import LangSelect from "../LangSelect";
import {IUser} from "../../utils/rest";

interface Props {
  setUser: (user: IUser) => void;
}

const Header: FC<Props> = ({setUser}) => {
  const {t} = useTranslation();

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrolling(scrollY >= 20)
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

      <header className={scrolling ? 'scrolling' : ''}>
        <div className='logo'>
          <ReactSVG src={logo}/>
        </div>
        <div className='header-right'>
          <LangSelect/>
          <button
            type='button'
            className={`${scrolling ? 'btn-gradient' : 'btn btn-primary'} ms-4`}
            onClick={() => window.location.href = 'https://console.zoundo.com/login'}
          >
            <div>
              {t('LOGIN')}
            </div>
          </button>
        </div>
      </header>

  );
}

export default Header;
