import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logo.png';
import TracksSlider from "./TracksSlider";
import {REG_LINK} from "../../utils/links";
import SectionText from "./SectionText";
import MobileTracksSider from "./MobileTracksSider";

import './section.css'
import SectionTextSlider from "./SectionTextSlider";
import slide_1 from "../../assets/images/slider/Unbreakable Bonds.jpg";
import slide_2 from "../../assets/images/slider/Eternal Flames.jpg";
import slide_3 from "../../assets/images/slider/Endless Affection.jpg";
import slide_4 from "../../assets/images/slider/Euphoric Love.jpg";
import slide_5 from "../../assets/images/slider/Forever and Always.jpg";
import slide_6 from "../../assets/images/slider/Heart's Desire.jpg";
import slide_7 from "../../assets/images/slider/Infinite Devotion.jpg";
import slide_8 from "../../assets/images/slider/Passion's Embrace.jpg";
import slide_9 from "../../assets/images/slider/Soulmates Forever.jpg";
import slide_10 from "../../assets/images/slider/Tenderly Yours.jpg";

import {API} from "../../utils/api";

const mochaData = [
  {id: 1, img: slide_1, name: 'Unbreakable Bonds', author: 'Emotiaura'},
  {id: 2, img: slide_2, name: 'Eternal Flames', author: 'Emotiaura'},
  {id: 3, img: slide_3, name: 'Endless Affection', author: 'Emotiaura'},
  {id: 4, img: slide_4, name: 'Euphoric Love', author: 'Emotiaura'},
  {id: 5, img: slide_5, name: 'Forever and Always', author: 'Emotiaura'},
  {id: 6, img: slide_6, name: 'Heart\'s Desire', author: 'Emotiaura'},
  {id: 7, img: slide_7, name: 'Infinite Devotion', author: 'Emotiaura'},
  {id: 8, img: slide_8, name: 'Passion\'s Embrace', author: 'Emotiaura'},
  {id: 9, img: slide_9, name: 'Soulmates Forever', author: 'Emotiaura'},
  {id: 10, img: slide_10, name: 'Tenderly Yours', author: 'Emotiaura'},
]

interface Props {
}

const Section1: FC<Props> = () => {
  const {t} = useTranslation();
  const [isMobile, setIsMobile] = useState(true);
  const [samples, setSamples] = useState();

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    try {
      // const res = API.
    } catch (e) {

    }
  }

  return (
    <section id={'section_1'}>
      <div className='flex-grow-1 pe-0 pe-lg-3'>
        <img src={logo} alt="logo" className='logo'/>
        {isMobile ? <SectionTextSlider/> : <SectionText/>}
        <a href={'#section_5'} className='btn-gradient'>{t('REGISTRATION')}</a>
      </div>
      {isMobile ? <MobileTracksSider tracks={mochaData}/> : <TracksSlider tracks={mochaData}/>}
    </section>
  );
}

export default Section1;