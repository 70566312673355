import React, {FC, InputHTMLAttributes} from 'react';

import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  icon?: any
}

const Input: FC<Props> = ({
                            label,
                            className,
                            icon,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group ${className || ''}`}>
      {label ? <label className='muted mb-2'>{t(label)}</label> : null}
      <div className='form-control-wrap'>
        <textarea className={`form-control${icon ? ' form-control-icon' : ''}`} {...props} />
        <div className="form-control-border" />
        <div className="form-control-gradient" />
        {icon ? <div className='input-icon'><ReactSVG src={icon}/></div> : null}
      </div>
    </div>
  );
}

export default Input;