import React, {FC} from 'react';
import logo from '../assets/images/logo_white.png';
import {useTranslation} from "react-i18next";

interface Props {
}

const Preview: FC<Props> = () => {
  const {t} = useTranslation();
  return (
      <div className='hello-page'>
        <img src={logo} alt="logo"/>
        <h1 className='text-gradient text-gradient-animated'>{t('HELLO_TITLE')}</h1>
      </div>
  );
}

export default Preview;